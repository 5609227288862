import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

interface ObservationsFilterState {
  searchText: string;
  selectedOrganizationalEntityIds: number[];
  selectedWaterbodyIds: number[];
  selectedInspectorIds: number[];
  selectedCategoryIds: number[];
  fromFilterTemporal: Moment | null | undefined;
  toFilterTemporal: Moment | null | undefined;
  selectedOrganizationalEntityIdsTemporal: number[];
  selectedWaterbodyIdsTemporal: number[];
  selectedInspectorIdsTemporal: number[];
  selectedCategoryIdsTemporal: number[];
}

const initialState: ObservationsFilterState = {
  searchText: '',
  selectedOrganizationalEntityIds: [],
  selectedWaterbodyIds: [],
  selectedInspectorIds: [],
  selectedCategoryIds: [],
  fromFilterTemporal: undefined,
  toFilterTemporal: undefined,
  selectedOrganizationalEntityIdsTemporal: [],
  selectedWaterbodyIdsTemporal: [],
  selectedInspectorIdsTemporal: [],
  selectedCategoryIdsTemporal: [],
};

const observationsFiltersSlice = createSlice({
  name: 'observationsFilters',
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setSelectedOrganizationalEntityIds(state, action: PayloadAction<number[]>) {
      state.selectedOrganizationalEntityIds = action.payload;
    },
    setSelectedWaterbodyIds(state, action: PayloadAction<number[]>) {
      state.selectedWaterbodyIds = action.payload;
    },
    setSelectedInspectorIds(state, action: PayloadAction<number[]>) {
      state.selectedInspectorIds = action.payload;
    },
    setSelectedCategoryIds(state, action: PayloadAction<number[]>) {
      state.selectedCategoryIds = action.payload;
    },
    setFromFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.fromFilterTemporal = action.payload;
    },
    setToFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.toFilterTemporal = action.payload;
    },
    setSelectedOrganizationalEntityIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedOrganizationalEntityIdsTemporal = action.payload;
    },
    setSelectedWaterbodyIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedWaterbodyIdsTemporal = action.payload;
    },
    setSelectedInspectorIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedInspectorIdsTemporal = action.payload;
    },
    setSelectedCategoryIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedCategoryIdsTemporal = action.payload;
    },
    resetFilters(state) {
      state.searchText = initialState.searchText;
      state.selectedOrganizationalEntityIds = initialState.selectedOrganizationalEntityIds;
      state.selectedWaterbodyIds = initialState.selectedWaterbodyIds;
      state.selectedInspectorIds = initialState.selectedInspectorIds;
      state.selectedCategoryIds = initialState.selectedCategoryIds;
      state.fromFilterTemporal = initialState.fromFilterTemporal;
      state.toFilterTemporal = initialState.toFilterTemporal;
      state.selectedOrganizationalEntityIdsTemporal = initialState.selectedOrganizationalEntityIdsTemporal;
      state.selectedWaterbodyIdsTemporal = initialState.selectedWaterbodyIdsTemporal;
      state.selectedInspectorIdsTemporal = initialState.selectedInspectorIdsTemporal;
      state.selectedCategoryIdsTemporal = initialState.selectedCategoryIdsTemporal;
    },
  },
});

export const {
  setSelectedOrganizationalEntityIds,
  setSelectedWaterbodyIds,
  setSelectedInspectorIds,
  setSelectedCategoryIds,
  setFromFilterTemporal,
  setToFilterTemporal,
  setSelectedOrganizationalEntityIdsTemporal,
  setSelectedWaterbodyIdsTemporal,
  setSelectedInspectorIdsTemporal,
  setSelectedCategoryIdsTemporal,
  resetFilters,
} = observationsFiltersSlice.actions;

export const areFiltersAppliedSelector = (state: { observationsFilters: ObservationsFilterState }) => {
  const {
    searchText,
    selectedOrganizationalEntityIds,
    selectedWaterbodyIds,
    selectedInspectorIds,
    selectedCategoryIds,
    fromFilterTemporal,
    toFilterTemporal,
  } = state.observationsFilters;

  return (
    searchText.length > 0 ||
    selectedOrganizationalEntityIds.length > 0 ||
    selectedWaterbodyIds.length > 0 ||
    selectedInspectorIds.length > 0 ||
    selectedCategoryIds.length > 0 ||
    fromFilterTemporal ||
    toFilterTemporal
  );
};

export const observationsFiltersActions = observationsFiltersSlice.actions;

export const observationsFilters = observationsFiltersSlice.reducer;
