import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

export interface HomeFilterState {
  isFilterOpen: boolean;
  selectedEventIds: string[];
  selectedTeamMemberIds: number[];
  selectedWaterbodyIds: number[];
  selectedLicenseIds: string[];
  selectedFisherIds: number[];
  selectedOrganizationalEntityIds: number[];
  fromFilterTemporal: Moment | null | undefined;
  toFilterTemporal: Moment | null | undefined;
  selectedEventIdsTemporal: string[];
  selectedTeamMemberIdsTemporal: number[];
  selectedWaterbodyIdsTemporal: number[];
  selectedLicenseIdsTemporal: string[];
  selectedFisherIdsTemporal: number[];
  selectedOrganizationalEntityIdsTemporal: number[];
}

const initialState: HomeFilterState = {
  isFilterOpen: false,
  selectedEventIds: [],
  selectedTeamMemberIds: [],
  selectedWaterbodyIds: [],
  selectedLicenseIds: [],
  selectedFisherIds: [],
  selectedOrganizationalEntityIds: [],
  fromFilterTemporal: undefined,
  toFilterTemporal: undefined,
  selectedEventIdsTemporal: [],
  selectedTeamMemberIdsTemporal: [],
  selectedWaterbodyIdsTemporal: [],
  selectedLicenseIdsTemporal: [],
  selectedFisherIdsTemporal: [],
  selectedOrganizationalEntityIdsTemporal: [],
};

const homeFiltersSlice = createSlice({
  name: 'homeFilters',
  initialState,
  reducers: {
    setIsFilterOpen(state, action: PayloadAction<boolean>) {
      state.isFilterOpen = action.payload;
    },
    setSelectedEventIds(state, action: PayloadAction<string[]>) {
      state.selectedEventIds = action.payload;
    },
    setSelectedTeamMemberIds(state, action: PayloadAction<number[]>) {
      state.selectedTeamMemberIds = action.payload;
    },
    setSelectedWaterbodyIds(state, action: PayloadAction<number[]>) {
      state.selectedWaterbodyIds = action.payload;
    },
    setSelectedLicenseIds(state, action: PayloadAction<string[]>) {
      state.selectedLicenseIds = action.payload;
    },
    setSelectedFisherIds(state, action: PayloadAction<number[]>) {
      state.selectedFisherIds = action.payload;
    },
    setSelectedOrganizationalEntityIds(state, action: PayloadAction<number[]>) {
      state.selectedOrganizationalEntityIds = action.payload;
    },
    setFromFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.fromFilterTemporal = action.payload;
    },
    setToFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.toFilterTemporal = action.payload;
    },
    setSelectedEventIdsTemporal(state, action: PayloadAction<string[]>) {
      state.selectedEventIdsTemporal = action.payload;
    },
    setSelectedTeamMemberIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedTeamMemberIdsTemporal = action.payload;
    },
    setSelectedWaterbodyIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedWaterbodyIdsTemporal = action.payload;
    },
    setSelectedLicenseIdsTemporal(state, action: PayloadAction<string[]>) {
      state.selectedLicenseIdsTemporal = action.payload;
    },
    setSelectedFisherIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedFisherIdsTemporal = action.payload;
    },
    setSelectedOrganizationalEntityIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedOrganizationalEntityIdsTemporal = action.payload;
    },
    resetFilters(state) {
      state.isFilterOpen = initialState.isFilterOpen;
      state.selectedEventIds = initialState.selectedEventIds;
      state.selectedTeamMemberIds = initialState.selectedTeamMemberIds;
      state.selectedWaterbodyIds = initialState.selectedWaterbodyIds;
      state.selectedLicenseIds = initialState.selectedLicenseIds;
      state.selectedFisherIds = initialState.selectedFisherIds;
      state.selectedOrganizationalEntityIds = initialState.selectedOrganizationalEntityIds;
      state.fromFilterTemporal = initialState.fromFilterTemporal;
      state.toFilterTemporal = initialState.toFilterTemporal;
      state.selectedEventIdsTemporal = initialState.selectedEventIdsTemporal;
      state.selectedTeamMemberIdsTemporal = initialState.selectedTeamMemberIdsTemporal;
      state.selectedWaterbodyIdsTemporal = initialState.selectedWaterbodyIdsTemporal;
      state.selectedLicenseIdsTemporal = initialState.selectedLicenseIdsTemporal;
      state.selectedFisherIdsTemporal = initialState.selectedFisherIdsTemporal;
      state.selectedOrganizationalEntityIdsTemporal = initialState.selectedOrganizationalEntityIdsTemporal;
    },
  },
});

export const {
  setSelectedEventIds,
  setSelectedTeamMemberIds,
  setSelectedWaterbodyIds,
  setSelectedLicenseIds,
  setSelectedFisherIds,
  setSelectedOrganizationalEntityIds,
  setFromFilterTemporal,
  setToFilterTemporal,
  setSelectedEventIdsTemporal,
  setSelectedTeamMemberIdsTemporal,
  setSelectedWaterbodyIdsTemporal,
  setSelectedLicenseIdsTemporal,
  setSelectedFisherIdsTemporal,
  setSelectedOrganizationalEntityIdsTemporal,
} = homeFiltersSlice.actions;

export const areFiltersAppliedSelector = (state: { homeFilters: HomeFilterState }) => {
  const {
    selectedEventIds,
    selectedTeamMemberIds,
    selectedWaterbodyIds,
    selectedLicenseIds,
    selectedFisherIds,
    selectedOrganizationalEntityIds,
    fromFilterTemporal,
    toFilterTemporal,
  } = state.homeFilters;

  return (
    selectedEventIds.length > 0 ||
    selectedTeamMemberIds.length > 0 ||
    selectedWaterbodyIds.length > 0 ||
    selectedLicenseIds.length > 0 ||
    selectedFisherIds.length > 0 ||
    selectedOrganizationalEntityIds.length > 0 ||
    fromFilterTemporal !== undefined ||
    toFilterTemporal !== undefined
  );
};

export const homeFiltersActions = homeFiltersSlice.actions;

export const homeFilters = homeFiltersSlice.reducer;
