import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

export interface BreedingJournalFilterState {
  selectedBreedingLocationsIds: number[];
  selectedFishTypesIds: number[];
  selectedOriginsIds: number[];
  selectedAges: number[];
  selectedStagesIds: number[];
  selectedDistrictsIds: number[];
  selectedActions: string[];
  fromFilterTemporal: Moment | null | undefined;
  toFilterTemporal: Moment | null | undefined;
  selectedBreedingLocationIdsTemporal: number[];
  selectedFishTypesIdsTemporal: number[];
  selectedOriginsIdsTemporal: number[];
  selectedAgesTemporal: number[];
  selectedStagesIdsTemporal: number[];
  selectedDistrictsIdsTemporal: number[];
  selectedActionsTemporal: string[];
}

const initialState: BreedingJournalFilterState = {
  selectedBreedingLocationsIds: [],
  selectedFishTypesIds: [],
  selectedOriginsIds: [],
  selectedAges: [],
  selectedStagesIds: [],
  selectedDistrictsIds: [],
  selectedActions: [],
  fromFilterTemporal: undefined,
  toFilterTemporal: undefined,
  selectedBreedingLocationIdsTemporal: [],
  selectedFishTypesIdsTemporal: [],
  selectedOriginsIdsTemporal: [],
  selectedAgesTemporal: [],
  selectedStagesIdsTemporal: [],
  selectedDistrictsIdsTemporal: [],
  selectedActionsTemporal: [],
};

const breedingJournalFiltersSlice = createSlice({
  name: 'breedingJournalFilters',
  initialState,
  reducers: {
    setSelectedBreedingLocationsIds(state, action: PayloadAction<number[]>) {
      state.selectedBreedingLocationsIds = action.payload;
    },
    setSelectedFishTypesIds(state, action: PayloadAction<number[]>) {
      state.selectedFishTypesIds = action.payload;
    },
    setSelectedOriginsIds(state, action: PayloadAction<number[]>) {
      state.selectedOriginsIds = action.payload;
    },
    setSelectedAges(state, action: PayloadAction<number[]>) {
      state.selectedAges = action.payload;
    },
    setSelectedStagesIds(state, action: PayloadAction<number[]>) {
      state.selectedStagesIds = action.payload;
    },
    setSelectedDistrictsIds(state, action: PayloadAction<number[]>) {
      state.selectedDistrictsIds = action.payload;
    },
    setSelectedActions(state, action: PayloadAction<string[]>) {
      state.selectedActions = action.payload;
    },
    setFromFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.fromFilterTemporal = action.payload;
    },
    setToFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.toFilterTemporal = action.payload;
    },
    setSelectedBreedingLocationIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedBreedingLocationIdsTemporal = action.payload;
    },
    setSelectedFishTypesIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedFishTypesIdsTemporal = action.payload;
    },
    setSelectedOriginsIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedOriginsIdsTemporal = action.payload;
    },
    setSelectedAgesTemporal(state, action: PayloadAction<number[]>) {
      state.selectedAgesTemporal = action.payload;
    },
    setSelectedStagesIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedStagesIdsTemporal = action.payload;
    },
    setSelectedDistrictsIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedDistrictsIdsTemporal = action.payload;
    },
    setSelectedActionsTemporal(state, action: PayloadAction<string[]>) {
      state.selectedActionsTemporal = action.payload;
    },
    resetFilters(state) {
      state.selectedBreedingLocationsIds = initialState.selectedBreedingLocationsIds;
      state.selectedFishTypesIds = initialState.selectedFishTypesIds;
      state.selectedOriginsIds = initialState.selectedOriginsIds;
      state.selectedAges = initialState.selectedAges;
      state.selectedStagesIds = initialState.selectedStagesIds;
      state.selectedDistrictsIds = initialState.selectedDistrictsIds;
      state.selectedActions = initialState.selectedActions;
      state.fromFilterTemporal = initialState.fromFilterTemporal;
      state.toFilterTemporal = initialState.toFilterTemporal;
      state.selectedBreedingLocationIdsTemporal = initialState.selectedBreedingLocationIdsTemporal;
      state.selectedFishTypesIdsTemporal = initialState.selectedFishTypesIdsTemporal;
      state.selectedOriginsIdsTemporal = initialState.selectedOriginsIdsTemporal;
      state.selectedAgesTemporal = initialState.selectedAgesTemporal;
      state.selectedStagesIdsTemporal = initialState.selectedStagesIdsTemporal;
      state.selectedDistrictsIdsTemporal = initialState.selectedDistrictsIdsTemporal;
      state.selectedActionsTemporal = initialState.selectedActionsTemporal;
    },
  },
});

export const {
  setSelectedBreedingLocationsIds,
  setSelectedFishTypesIds,
  setSelectedOriginsIds,
  setSelectedAges,
  setSelectedStagesIds,
  setSelectedDistrictsIds,
  setSelectedActions,
  setSelectedBreedingLocationIdsTemporal,
  setSelectedFishTypesIdsTemporal,
  setSelectedOriginsIdsTemporal,
  setSelectedAgesTemporal,
  setSelectedStagesIdsTemporal,
  setSelectedDistrictsIdsTemporal,
  setSelectedActionsTemporal,
} = breedingJournalFiltersSlice.actions;

export const areFiltersAppliedSelector = (state: { breedingJournalFilters: BreedingJournalFilterState }) => {
  const {
    selectedBreedingLocationsIds,
    selectedFishTypesIds,
    selectedOriginsIds,
    selectedAges,
    selectedStagesIds,
    selectedDistrictsIds,
    selectedActions,
    fromFilterTemporal,
    toFilterTemporal,
    selectedBreedingLocationIdsTemporal,
    selectedFishTypesIdsTemporal,
    selectedOriginsIdsTemporal,
    selectedAgesTemporal,
    selectedStagesIdsTemporal,
    selectedDistrictsIdsTemporal,
    selectedActionsTemporal,
  } = state.breedingJournalFilters;

  return (
    selectedBreedingLocationsIds.length > 0 ||
    selectedFishTypesIds.length > 0 ||
    selectedOriginsIds.length > 0 ||
    selectedAges.length > 0 ||
    selectedStagesIds.length > 0 ||
    selectedDistrictsIds.length > 0 ||
    selectedActions.length > 0 ||
    fromFilterTemporal !== undefined ||
    toFilterTemporal !== undefined ||
    selectedBreedingLocationIdsTemporal.length > 0 ||
    selectedFishTypesIdsTemporal.length > 0 ||
    selectedOriginsIdsTemporal.length > 0 ||
    selectedAgesTemporal.length > 0 ||
    selectedStagesIdsTemporal.length > 0 ||
    selectedDistrictsIdsTemporal.length > 0 ||
    selectedActionsTemporal.length > 0
  );
};

export const breedingJournalFiltersActions = breedingJournalFiltersSlice.actions;

export const breedingJournalFilters = breedingJournalFiltersSlice.reducer;
