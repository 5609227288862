import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

export interface InspectionsFiltersState {
  onlyWithPenalties: boolean;
  onlyInvoiceNotPaid: boolean;
  selectedOrganizationalEntityIds: number[];
  selectedWaterbodyIds: number[];
  selectedInspectorIds: number[];
  selectedLicenseIds: string[];
  selectedFisherIds: number[];
  fromFilterTemporal: Moment | null | undefined;
  toFilterTemporal: Moment | null | undefined;
  selectedOrganizationalEntityIdsTemporal: number[];
  selectedWaterbodyIdsTemporal: number[];
  selectedInspectorIdsTemporal: number[];
  selectedLicenseIdsTemporal: string[];
  selectedFisherIdsTemporal: number[];
}

const initialState: InspectionsFiltersState = {
  onlyWithPenalties: false,
  onlyInvoiceNotPaid: false,
  selectedOrganizationalEntityIds: [],
  selectedWaterbodyIds: [],
  selectedInspectorIds: [],
  selectedLicenseIds: [],
  selectedFisherIds: [],
  fromFilterTemporal: undefined,
  toFilterTemporal: undefined,
  selectedOrganizationalEntityIdsTemporal: [],
  selectedWaterbodyIdsTemporal: [],
  selectedInspectorIdsTemporal: [],
  selectedLicenseIdsTemporal: [],
  selectedFisherIdsTemporal: [],
};

const inspectionsFiltersSlice = createSlice({
  name: 'inspectionsFilters',
  initialState,
  reducers: {
    setOnlyWithPenalties(state, action: PayloadAction<boolean>) {
      state.onlyWithPenalties = action.payload;
    },
    setOnlyInvoiceNotPaid(state, action: PayloadAction<boolean>) {
      state.onlyInvoiceNotPaid = action.payload;
    },
    setSelectedOrganizationalEntityIds(state, action: PayloadAction<number[]>) {
      state.selectedOrganizationalEntityIds = action.payload;
    },
    setSelectedWaterbodyIds(state, action: PayloadAction<number[]>) {
      state.selectedWaterbodyIds = action.payload;
    },
    setSelectedInspectorIds(state, action: PayloadAction<number[]>) {
      state.selectedInspectorIds = action.payload;
    },
    setSelectedLicenseIds(state, action: PayloadAction<string[]>) {
      state.selectedLicenseIds = action.payload;
    },
    setSelectedFisherIds(state, action: PayloadAction<number[]>) {
      state.selectedFisherIds = action.payload;
    },
    setFromFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.fromFilterTemporal = action.payload;
    },
    setToFilterTemporal(state, action: PayloadAction<Moment | null | undefined>) {
      state.toFilterTemporal = action.payload;
    },
    setSelectedOrganizationalEntityIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedOrganizationalEntityIdsTemporal = action.payload;
    },
    setSelectedWaterbodyIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedWaterbodyIdsTemporal = action.payload;
    },
    setSelectedInspectorIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedInspectorIdsTemporal = action.payload;
    },
    setSelectedLicenseIdsTemporal(state, action: PayloadAction<string[]>) {
      state.selectedLicenseIdsTemporal = action.payload;
    },
    setSelectedFisherIdsTemporal(state, action: PayloadAction<number[]>) {
      state.selectedFisherIdsTemporal = action.payload;
    },
    resetFilters(state) {
      state.onlyWithPenalties = initialState.onlyWithPenalties;
      state.onlyInvoiceNotPaid = initialState.onlyInvoiceNotPaid;
      state.selectedOrganizationalEntityIds = initialState.selectedOrganizationalEntityIds;
      state.selectedWaterbodyIds = initialState.selectedWaterbodyIds;
      state.selectedInspectorIds = initialState.selectedInspectorIds;
      state.selectedLicenseIds = initialState.selectedLicenseIds;
      state.selectedFisherIds = initialState.selectedFisherIds;
      state.fromFilterTemporal = initialState.fromFilterTemporal;
      state.toFilterTemporal = initialState.toFilterTemporal;
      state.selectedOrganizationalEntityIdsTemporal = initialState.selectedOrganizationalEntityIdsTemporal;
      state.selectedWaterbodyIdsTemporal = initialState.selectedWaterbodyIdsTemporal;
      state.selectedInspectorIdsTemporal = initialState.selectedInspectorIdsTemporal;
      state.selectedLicenseIdsTemporal = initialState.selectedLicenseIdsTemporal;
      state.selectedFisherIdsTemporal = initialState.selectedFisherIdsTemporal;
    },
  },
});

export const {
  setOnlyWithPenalties,
  setOnlyInvoiceNotPaid,
  setSelectedOrganizationalEntityIds,
  setSelectedWaterbodyIds,
  setSelectedInspectorIds,
  setSelectedLicenseIds,
  setSelectedFisherIds,
  setFromFilterTemporal,
  setToFilterTemporal,
  setSelectedOrganizationalEntityIdsTemporal,
  setSelectedWaterbodyIdsTemporal,
  setSelectedInspectorIdsTemporal,
  setSelectedLicenseIdsTemporal,
  setSelectedFisherIdsTemporal,
} = inspectionsFiltersSlice.actions;

export const areFiltersAppliedSelector = (state: { inspectionsFilters: InspectionsFiltersState }) => {
  const {
    onlyWithPenalties,
    onlyInvoiceNotPaid,
    selectedOrganizationalEntityIds,
    selectedWaterbodyIds,
    selectedInspectorIds,
    selectedLicenseIds,
    selectedFisherIds,
    fromFilterTemporal,
    toFilterTemporal,
  } = state.inspectionsFilters;

  return (
    onlyWithPenalties ||
    onlyInvoiceNotPaid ||
    selectedOrganizationalEntityIds.length > 0 ||
    selectedWaterbodyIds.length > 0 ||
    selectedInspectorIds.length > 0 ||
    selectedLicenseIds.length > 0 ||
    selectedFisherIds.length > 0 ||
    fromFilterTemporal !== undefined ||
    toFilterTemporal !== undefined
  );
};

export const inspectionsFiltersActions = inspectionsFiltersSlice.actions;

export const inspectionsFilters = inspectionsFiltersSlice.reducer;
