import React from 'react';
import { LayoutOutlined } from '@ant-design/icons';
// import { ReactComponent as InspectionsIcon } from '@app/assets/icons/app.svg';
import { ReactComponent as InspectionsIcon } from '@app/assets/admin-icons/AdminTool_Icons_P_B-Kontrollen.svg';
import { ReactComponent as ObservationsIcon } from '@app/assets/admin-icons/AdminTool_Icons_P_L-Beobachtungen.svg';
import { ReactComponent as BreedingJournalIcon } from '@app/assets/admin-icons/AdminTool_Icons_P_BS-Zuchtbuch.svg';
import { ReactComponent as HomeIcon } from '@app/assets/admin-icons/AdminTool_Icons_P_Home.svg';
import { BREEDING_JOURNAL_PATH, HOME_PATH, INSPECTIONS_PATH, OBSERVATIONS_PATH } from '@app/constants/routerPaths';
// import { ReactComponent as ImportPaperStatisticsIcon } from '@app/assets/icons/import.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.home',
    key: 'home',
    url: HOME_PATH,
    icon: <HomeIcon />,
  },
  {
    title: 'common.inspections',
    key: 'inspections',
    url: INSPECTIONS_PATH,
    icon: <InspectionsIcon style={{ fill: 'none' }} />,
  },
  {
    title: 'common.observations',
    key: 'observations',
    url: OBSERVATIONS_PATH,
    icon: <ObservationsIcon />,
  },
  {
    title: 'common.breedingJournal',
    key: 'breedingJournal',
    url: BREEDING_JOURNAL_PATH,
    icon: <BreedingJournalIcon style={{ fill: 'none' }} />,
  },
  // {
  //   title: 'common.importPaperStatistics',
  //   key: 'import-paper-statistics',
  //   // TODO use path variable
  //   url: '/import-paper-statistics',
  //   icon: <ImportPaperStatisticsIcon style={{ fill: 'white', width: 22, height: 22 }} />,
  // },
  {
    title: 'common.profile',
    key: 'pages',
    icon: <LayoutOutlined />,
    children: [
      {
        title: 'common.personalInfo',
        key: 'profile',
        url: '/profile',
      },
    ],
  },
];
